import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";
import Brand from "./../brands/Brand";
import BrandService from "./../brandServices/BrandService";
import OffersFilter from "./OffersFilter";
import offerImg from "@/assets/images/offers.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import i18n from "@/i18n";
import store from "@/store";
import { date2slash } from "@/utils/functions";

export default class Offer extends BaseEntityModel {
  defaultImg = offerImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.offerToken = "";
    this.offerTitleCurrent = "";
    this.offerTitleEn = "";
    this.offerTitleAr = "";
    this.offerTitleUnd = "";
    this.offerStartDateTime = "";
    this.offerStartDate = "";
    this.offerStartTime = "";
    this.offerEndDateTime = "";
    this.offerEndDate = "";
    this.offerEndTime = "";
    this.offerDuration = "";
    this.offerDiscountPercentage = "";
    this.offerDescriptionCurrent = "";
    this.offerDescriptionAr = "";
    this.offerDescriptionEn = "";
    this.offerDescriptionUnd = "";
    this.offerImagePath = "";
    this.offerNotes = "";
    this.offerArchiveStatus = "";
    this.offerPreferedTypeToken = "";
    this.offerPreferedTypeNameCurrent = "";
    this.offerPreferedTypeNameEn = "";
    this.offerPreferedTypeNameAr = "";
    this.offerPreferedTypeNameUnd = "";
    this.brandToken = "";
    this.brandData = new Brand();
    this.brandServiceToken = "";
    this.brandServiceData = new BrandService();
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.offerToken = data.offerToken;
      this.offerTitleCurrent = data.offerTitleCurrent;
      this.offerTitleEn = data.offerTitleEn;
      this.offerTitleAr = data.offerTitleAr;
      this.offerTitleUnd = data.offerTitleUnd;
      this.offerStartDateTime = data.offerStartDateTime;
      this.offerStartDate = data.offerStartDate;
      this.offerStartTime = data.offerStartTime;
      this.offerEndDateTime = data.offerEndDateTime;
      this.offerEndDate = data.offerEndDate;
      this.offerEndTime = data.offerEndTime;
      this.offerDuration = data.offerDuration;
      this.offerDiscountPercentage = data.offerDiscountPercentage;
      this.offerDescriptionCurrent = data.offerDescriptionCurrent;
      this.offerDescriptionAr = data.offerDescriptionAr;
      this.offerDescriptionEn = data.offerDescriptionEn;
      this.offerDescriptionUnd = data.offerDescriptionUnd;
      this.offerImagePath = data.offerImagePath;
      this.offerNotes = data.offerNotes;
      this.offerArchiveStatus = data.offerArchiveStatus;
      this.offerPreferedTypeToken = data.offerPreferedTypeToken;
      this.offerPreferedTypeNameCurrent = data.offerPreferedTypeNameCurrent;
      this.offerPreferedTypeNameEn = data.offerPreferedTypeNameEn;
      this.offerPreferedTypeNameAr = data.offerPreferedTypeNameAr;
      this.offerPreferedTypeNameUnd = data.offerPreferedTypeNameUnd;
      this.brandToken = data.brandToken;
      this.brandData.fillData(data.brandData);
      this.brandServiceToken = data.brandServiceToken;
      this.brandServiceData.fillData(data.brandServiceData);
    }
  }

  async getAllOffers(language, userAuthorizeToken, pagination, filterData) {
    return await axios.get(
      `/api/Offers/GetAllOffers?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&includeBrandDataStatus=true&includeBrandServiceDataStatus=true&brandToken=${filterData.brandToken}&brandServiceToken=${filterData.brandServiceToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getOfferDialog(
    language,
    userAuthorizeToken,
    filterData = new OffersFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/Offers/GetOfferDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&brandToken=${filterData.brandToken}&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : offerImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: offerImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: offerImg,
      });
    }
    return options;
  }

  async getOfferDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/Offers/GetOfferDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.offerToken}`
    );
  }

  async addOrUpdateOffer(language, userAuthorizeToken) {
    this.offerStartDateTime =
      date2slash(this.offerStartDate) + " " + this.offerStartTime;
    this.offerEndDateTime =
      date2slash(this.offerEndDate) + " " + this.offerEndTime;
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("offerToken", this.offerToken);
    formData.append("brandToken", this.brandToken);
    formData.append("brandServiceToken", this.brandServiceToken);
    formData.append("fullCode", this.fullCode);
    formData.append("offerTitleEn", this.offerTitleEn);
    formData.append("offerTitleAr", this.offerTitleAr);
    formData.append("offerStartDateTime", this.offerStartDateTime);
    formData.append("offerEndDateTime", this.offerEndDateTime);
    formData.append("offerDiscountPercentage", this.offerDiscountPercentage);
    formData.append("offerDescriptionAr", this.offerDescriptionAr);
    formData.append("offerDescriptionEn", this.offerDescriptionEn);
    formData.append("offerPreferedTypeToken", this.offerPreferedTypeToken);
    formData.append("offerNotes", this.offerNotes);

    if (this.offerToken == "" || this.offerToken == undefined) {
      return await axios.post(`/api/Offers/AddOffer`, formData);
    } else {
      return await axios.post(`/api/Offers/UpdateOffer`, formData);
    }
  }

  async archiveOffer(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.offerToken,
      archiveStatus: true,
    };

    return await axios.post(`/api/Offers/ArchiveOffer`, data);
  }
}
